import React from "react";
import "./style.css";

export const Desktop = () => {
  return (
    <div className="desktop">
      <div className="frame">
        <div className="div-wrapper">
          <h1 className="the-o-s-randolph">
            <span className="text-wrapper">The</span> 
           <span> O.S. Randolph Group </span>
          </h1>
        </div>
        <div className="div-wrapper">
          <p className="strategic-marketing">
            Strategic Marketing Consultants Specializing 
            in Social Media &amp; Brand Building
          </p>
        </div>
  <a class="div" href="mailto:omarj@osrandolphgroup.com?subject=Book%20a%20Consultation">
    <img class="calendar-lines" alt="Calendar lines" src="/img/calendar-lines-1-1.svg" />
    <div class="book-a-consult">BOOK A CONSULT</div>
  </a>
      </div>
      <div className="frame-2">
        <div className="text-wrapper-2">O.S. Randolph Group Companies</div>
        <div className="frame-3">
          <a className="text-wrapper-2" target="_blank" href="https://tmitalent.com/"> TMI Talent</a>
          <a className="text-wrapper-3" target="_blank" href="http://notasneakerhead.com/">TMI Sneakers</a>
        </div>
      </div>
    </div>
  );
};
